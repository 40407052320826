import { Component, Watch } from 'vue-property-decorator'
import Container from 'typedi'
import Notification from '@/modules/common/services/notification.service'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import ChangeLog from '@/modules/common/components/changelog/changelog.vue'
import { mapState } from 'vuex'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import { EmailTemplate } from '@/modules/level-two/models/email.model'

@Component({
  name: 'GtrEventNewEmailView',
  computed: {
    ...mapState('email', ['changelog', 'currentlyDeployedLiveUUID', 'currentDevUUID']),
    ...mapState('formbuilder', ['emailerFields']),
    ...mapState('option', ['option_groups'])
  },
  components: {
    changelog: ChangeLog
  }
})
export default class GtrEventNewEmailView extends GtrSuper {
  emailerFields!: Array<Record<string, any>>;

  option_groups!: Array<Record<string, any>>;

  data () {
    return {
      emailVersion: null,
      loading: false,
      submitting: false,
      regTypeModalOpen: false,
      regTypesToAdd: [],
      currentTab: 0,
      regTypeTemplates: [
        {
          regTypeName: 'Default',
          regTypeUuid: '_default',
          event_uuid: '',
          email_data: {
            company_uuid: '',
            language: 'en',
            name: '',
            subject: {},
            category: '',
            cc: null,
            bcc: null,
            body: {},
            to_field: 'email',
            wrapper: null
          },
          email_criteria: {
            type: '*'
          }
        }
      ] as EmailTemplate[],
      languages: [],
      templateToCopy: null as number|null,
      changeLogModel: {
        changeLog: [],
        currentDevUUID: null,
        currentlyDeployedLiveUUID: null
      },
      event_uuid: this.$route.params.event_uuid,
      afterSave: false,
      selectedItem: 1,
      glossaryItems: []
    }
  }

  created () {
    this.$bus.$on('gtr.event.content_all', this.getLanguages)
  }

  @Watch('changelog')
  onChangeLogChange (payload: any) {
    if (payload) {
      this.$data.changeLogModel.changeLog = payload.data
    }
  }

  @Watch('currentDevUUID')
  onCurrentDevUUIDChange (payload: any) {
    if (payload.dev) {
      this.$data.changeLogModel.currentDevUUID = payload.dev.uuid
    }
  }

  @Watch('currentlyDeployedLiveUUID')
  onCurrentlyDeployedLiveUUIDChange (payload: any) {
    if (payload.live) {
      this.$data.changeLogModel.currentlyDeployedLiveUUID = payload.live.uuid
    }
  }

  @Watch('emailVersion', { deep: true })
  async onEmailVersionChange (payload: any) {
    if (payload) {
      const response = await this.$store.dispatch('email/fetchEmail', { event_uuid: this.$route.params.event_uuid, email_uuid: payload })
      if (response) {
        this.$data.regTypeTemplates = response.data.page_data
        for (const template of this.$data.regTypeTemplates) {
          template.email_data.language = 'en'
          template.event_uuid = this.$route.params.event_uuid
          if (template.email_criteria?.value) {
            template.regTypeUuid = template.email_criteria.value
            template.regTypeName = this.registrationTypes[template.email_criteria.value]
          } else {
            template.regTypeUuid = '_default'
            template.regTypeName = 'Default'
          }
        }
        this.$data.changeLogModel.currentDevUUID = payload
      }
      Container.get(Notification).success('Email version successfully changed.')
      this.$router.push({
        name: 'level-two.event.emails.edit',
        params: {
          email_uuid: payload,
          event_uuid: this.$route.params.event_uuid
        }
      })
    }
  }

  async mounted () {
    this.$data.regTypeTemplates[0].email_data.company_uuid = this.$route.params.uuid
    this.$data.regTypeTemplates[0].event_uuid = this.$route.params.event_uuid
    if (!this.$store.state.option?.option_groups.length) {
      try {
        await this.$store.dispatch('option/getOptionsGroup', { event_uuid: this.$route.params.event_uuid })
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      }
    }
    this.disablePaste('name')
    await this.getLanguages()
    const response = await this.$store.dispatch('formbuilder/getEmailerFields', { event_uuid: this.$route.params.event_uuid })
    if (response.data) {
      this.$data.glossaryItems = response.data
    }
  }

  get registrationTypes () {
    if (this.option_groups?.length) {
      for (let i = 0; i < this.option_groups.length; i++) {
        if (this.option_groups[i].name === 'Registration Types') {
          return this.option_groups[i].options.reduce((acc, regType) => {
            acc[regType.uuid] = regType.name
            return acc
          }, {})
        }
      }
    }
    return {}
  }

  get unusedRegTypes () {
    const regTypes: any = {}
    for (const uuid in this.registrationTypes) {
      if (!this.$data.regTypeTemplates.some(template => template.regTypeUuid === uuid)) {
        regTypes[uuid] = this.registrationTypes[uuid]
      }
    }
    return regTypes
  }

  get regTypesOptionGroupUuid () {
    if (this.option_groups?.length) {
      const [group] = this.option_groups.filter(group => group.name === 'Registration Types')
      return group.uuid
    }
    return ''
  }

  get currentRegTypeEmailData () {
    return this.$data.regTypeTemplates[this.$data.currentTab]?.email_data ?? {}
  }

  openRegTypeModal () {
    this.$data.regTypeModalOpen = true
  }

  closeRegTypeModal () {
    this.$data.regTypeModalOpen = false
  }

  addRegTypeTemplates () {
    while (this.$data.regTypesToAdd.length) {
      const regTypeUuid = this.$data.regTypesToAdd.pop()
      this.$data.regTypeTemplates.push({
        regTypeName: this.registrationTypes[regTypeUuid],
        regTypeUuid: regTypeUuid,
        event_uuid: this.$route.params.event_uuid,
        email_data: {
          company_uuid: this.$route.params.uuid,
          language: 'en',
          name: this.$data.regTypeTemplates[0].email_data.name,
          subject: {},
          category: '',
          cc: null,
          bcc: null,
          body: {},
          to_field: 'email',
          wrapper: null
        },
        email_criteria: {
          field: this.regTypesOptionGroupUuid,
          global_type: 'single',
          group_items: [],
          group_operator: '',
          operator: '=',
          type: 'option_criteria',
          value: regTypeUuid,
          visible: true
        }
      })
    }
    this.closeRegTypeModal()
  }

  copyEmailContent () {
    this.$data.templateToCopy = this.$data.currentTab
  }

  pasteEmailContent () {
    const emailDataToCopy = this.$data.regTypeTemplates[this.$data.templateToCopy].email_data
    this.currentRegTypeEmailData.body[this.currentRegTypeEmailData.language] = emailDataToCopy.body[emailDataToCopy.language]
  }

  handleUpdateEmailVersion (payload: any) {
    if (payload) {
      this.$data.emailVersion = payload
    }
  }

  async handleDeployed () {
    await this.fetchChangeLogData()
  }

  private async fetchChangeLogData () {
    try {
      this.$data.loading = true
      const event_uuid = this.$data.event_uuid
      const emailName = this.$data.regTypeTemplates[0].email_data.name
      const changelogData = { type: 'email', subtype: emailName, event_uuid }
      const deployData = { type: 'email', sub_type: emailName, event_uuid }
      await this.$store.dispatch('email/getChangelog', changelogData)
      await this.$store.dispatch('email/getCurrentlyDeployedLiveUUID', deployData)
      await this.$store.dispatch('email/getCurrentDevUUID', deployData)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  get passedValidation () {
    for (let i = 0; i < this.$data.regTypeTemplates.length; i++) {
      const templateFields = this.$data.regTypeTemplates[i].email_data
      if (!templateFields.name || !templateFields.subject?.en || !templateFields.category || !templateFields.body?.en) {
        return false
      }
    }
    return true
  }

  async submit () {
    try {
      this.$data.submitting = true
      await this.$store.dispatch('email/createEmail', this.$data.regTypeTemplates)
      Container.get(Notification).success('Email successfully created.')
      this.$bus.$emit('gtr.email_created')
      this.$data.afterSave = true
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.submitting = false
    }
  }

  email_name () {
    const name = this.$data.regTypeTemplates[this.$data.currentTab].email_data.name
    if (name) {
      this.$data.regTypeTemplates.forEach(regTypeTemplate => {
        regTypeTemplate.email_data.name = name.replace(/[^\w]/gi, '_')
      })
    }
  }

  private async getLanguages () {
    await this.$store.dispatch('languages/fetchLanguages', this.$route.params.event_uuid)
    this.$store.state.languages.languages.map((language: any) => {
      this.$data.languages.push({ value: language.name, text: language.display })
    })
  }
}
